.styled-table {
  border-collapse: collapse;
  margin: 25px 0;
  font-size: 1em;
  font-family: sans-serif;
  min-width: 400px;
  text-align: left;
}
.styled-table thead {
  background-color: #212F49;
  border-radius: 12px;
}

.styled-table thead tr {
  text-align: left;
}

.styled-table th {
  color: #ffffff !important;
}

.styled-table th,
.styled-table td {
  padding: 24px 15px;
  color: #737373;
}

.styled-table tbody tr {
  border-bottom: 1px solid #dddddd;
  background-color: #FFFFFF;
}

.styled-table tbody tr:nth-of-type(even) {
  background-color: #FFFFFF ;
}

.styled-table tbody tr.active-row {
  font-weight: bold;
  color: #212F49;
}
